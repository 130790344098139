import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Moment from 'react-moment'
import Overlay from './assets/blue.jpg'
import OverlayCareer from './assets/yellow.jpg'
import { excerpt } from '@/functions'

interface Terms {
  name: string
  id: string
}

export interface PostHeaderData {
  heading?: string
  date?: string
  author: {
    name?: string
    content?: string
    education?: string
    workplace?: string
    worktitle?: string
    workyears?: string
    image?: any
  }
  terms?: {
    nodes: Array<Terms>
  }
  isCareer?: boolean
  authorString?: string
}

const PostHeader = ({
  author: { name, content, image, education, workplace, workyears, worktitle },
  heading,
  date,
  terms,
  authorString = 'Kirjoittaja',
  isCareer = false,
}: PostHeaderData) => {
  const avatar = image && getImage(image?.localFile)

  const hasBox = image || name

  return (
    <div
      className={`post-header mt-10 mb-20 grid ${!hasBox ? 'grid-cols-1 max-w-6xl mx-auto' : 'lg:grid-cols-2'} gap-10`}
    >
      <div className="flex flex-col">
        {isCareer ? (
          <p className="mb-2 text-xl">
            {terms?.nodes.map((term: any) => {
              return <span key={term.id}>{term.name}</span>
            })}
          </p>
        ) : (
          <p className="mb-2 text-xl">
            <Moment format="DD.MM.YYYY" date={date} />
          </p>
        )}
        <h1 className="text-4xl md:text-4xl break-words mb-2 wordbreak md:leading-[3rem]">{heading}</h1>
        {name && !isCareer && (
          <p className="text-lg mt-auto">
            {authorString}: {name}
          </p>
        )}
        {isCareer && (
          <div className="mt-auto">
            {worktitle && isCareer && (
              <p className="text-lg mt-2">
                <span className="font-FuturaBol">Työnimike:</span> {worktitle}
              </p>
            )}
            {workplace && isCareer && (
              <p className="text-lg mt-2">
                <span className="font-FuturaBol">Työpaikka:</span> {workplace}
              </p>
            )}
            {workyears && isCareer && (
              <p className="text-lg mt-2">
                <span className="font-FuturaBol">Työvuosia:</span> {workyears}
              </p>
            )}
          </div>
        )}
      </div>
      {hasBox ? (
        <div
          style={{ backgroundImage: `url(${isCareer ? OverlayCareer : Overlay})` }}
          className="w-full flex items-center relative overflow-hidden rounded-3xl shadow-md bg-no-repeat bg-cover bg-center"
        >
          <div className="py-5 px-5 sm:py-14 sm:px-10 w-full flex-col sm:flex-row flex flex-shrink-0">
            {avatar && (
              <div className="mb-5 sm:mb-0 sm:mr-10 w-2/4 h-2/4 sm:w-1/3 sm:h-1/3 flex-shrink-0 rounded-full overflow-hidden relative object-cover">
                <GatsbyImage
                  image={avatar}
                  className="rounded-full aspect-square bg-no-repeat bg-cover bg-center person-image z-0"
                  alt={'Image'}
                />
              </div>
            )}
            <div className="z-10 relative">
              <p className={`text-3xl mb-5 break-words font-FuturaBol ${isCareer ? 'text-black' : 'text-white'}`}>
                {name}
              </p>
              {isCareer ? (
                <p className={`text-lg break-words max-w-sm font-futuraBook ${isCareer ? 'text-black' : 'text-white'}`}>
                  {education}
                </p>
              ) : (
                <p className={`text-lg break-words max-w-sm font-futuraBook ${isCareer ? 'text-black' : 'text-white'}`}>
                  {excerpt(content)}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default PostHeader
