import React from 'react'

export interface CareerCardProps {
  heading: string
  value: string
  showPath?: boolean
}

const CareerCard = ({ heading, value, showPath }: CareerCardProps) => {
  return (
    <div className="relative flex career-card">
      <section
        className={`bg-yellow flex-col flex text-black py-4 px-3 rounded-2xl text-center shadow-lg info-box min-h-[160px] w-full`}
      >
        <p className="text-3xl md:text-4xl xl:text-5xl tracking-wide leading-10 font-FuturaBol mt-4">{value}</p>
        <p className="text-lg xl:text-xl mt-1 font-FuturaBol mb-2">{heading}</p>
      </section>
      {showPath && (
        <>
          <div className="w-[100px] h-[8px] bg-yellow absolute right-[-50px] z-[-1] top-[50%] hidden sm:block line"></div>
          <div className="w-[8px] h-[100px] bg-yellow absolute right-[48%] z-[-1] bottom-[-50px] sm:hidden block"></div>
        </>
      )}
    </div>
  )
}

export default CareerCard
