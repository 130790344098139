import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import PostHeader from '@/components/PostHeader'
import CareerPath from '@/components/CareerPath'

interface Terms {
  name: string
  id: string
}

interface Career {
  job: string
  year: string
}

interface DefaultData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
      education: string
      personname: string
      persontitle: string
      workplace: string
      workyears: string
      careertitle: string
      personimg: any
      gwCareerPath: Array<Career>
      terms: {
        nodes: Array<Terms>
      }
    }
    menu?: any
    quickmenu?: any
    wp: any
  }
  pageContext: {
    postlang: string
  }
}

const SingleCareerTemplate = ({ data }: DefaultData) => {
  // Page data
  const { page, wp } = data

  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
  }

  const postAuthor = {
    name: page?.personname,
    image: page?.personimg,
    worktitle: page?.persontitle,
    education: page?.education,
    workplace: page?.workplace,
    workyears: page?.workyears,
  }

  return (
    <Layout
      title={'Uratarinat'}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
      customBreadCrump={wp?.options?.customBreadCrump}
      archiveUrl={wp?.options?.archiveurl}
    >
      <PostHeader isCareer={true} heading={page?.title} author={postAuthor} terms={page?.terms} />
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
        {page?.gwCareerPath && <CareerPath career={page?.gwCareerPath} heading={page?.careertitle} />}
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query CareerPostById($id: String) {
    page: wpGwStory(id: { eq: $id }) {
      id
      content
      title
      uri
      education: gwCareerPersonEducation
      personname: gwCareerPersonName
      persontitle: gwCareerPersonTitle
      workplace: gwCareerPersonWorkplace
      workyears: gwCareerPersonWorkyears
      careertitle: gwCareerPathTittle
      heroFilter: gwShowPostHeaderFilter
      gwCareerPath {
        job
        year
      }
      terms {
        nodes {
          id
          name
        }
      }
      personimg: gwCareerPersonImage {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      ...seoCareer
      ...featuredHeroImageCareer
      ...careerBlocks
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    wp {
      options: crbThemeOptions {
        customBreadCrump: gwBreadcrumpsGwStories {
          link
        }
        archiveurl: gwCareerArchiveUrl
      }
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default SingleCareerTemplate
