import React from 'react'
import CareerCard from './CareerCard'

interface Career {
  job: string
  year: string
}

export interface CareerPathProps {
  career: Array<Career>
  heading?: string
}

const CareerPath = ({ career, heading }: CareerPathProps) => {
  return (
    <>
      {heading && (
        <div>
          <h2 className="text-2xl mb-8 md:mt-14">{heading}</h2>
        </div>
      )}
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {career?.map((career, i: number, row) => {
          if (i + 1 === row.length) {
            return <CareerCard heading={career?.job} value={career?.year} showPath={false} />
          } else {
            return <CareerCard heading={career?.job} value={career?.year} showPath={true} />
          }
        })}
      </div>
    </>
  )
}

export default CareerPath
